@import '../fonts/raleway/font-raleway.css';
@import '../fonts/mulish/font-mulish.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-hlr-gunmetal;
    @apply scroll-pt-40;
  }
  h1 {
    @apply text-h1-hero;
  }
  h2 {
    @apply text-h2;
  }
  h3 {
    @apply text-h3;
  }
  h4 {
    @apply text-lg font-bold;
  }
  h5 {
    @apply font-bold;
  }
}

@layer components {
  .text-h1-hero {
    @apply text-28px md:text-40px font-raleway leading-relaxed;
  }

  .text-h2 {
    @apply text-22px md:text-32px font-raleway;
  }

  .text-h3 {
    @apply text-base md:text-xl font-raleway font-bold;
  }

  .text-pre-header {
    @apply text-sm md:text-base tracking-hlr-wide uppercase font-raleway font-bold;
  }

  .text-body-hero {
    @apply text-sm md:text-base font-light leading-relaxed;
  }

  .custom-divider-red {
    @apply h-1 md:h-2 w-20 bg-custom-divider-red my-4;
  }

  .custom-item-category {
    @apply text-xs font-bold uppercase;
  }

  .custom-item-name {
    @apply md:text-lg font-bold;
  }

  .custom-item-price {
    @apply md:text-lg font-bold;
  }

  .custom-item-button-text {
    @apply text-white text-sm font-semibold;
  }

  .text-caption {
    @apply text-xs font-light;
  }

  .form-checkbox {
    @apply h-8 w-8 cursor-pointer rounded-lg border-hlr-gunmetal text-hlr-green focus:ring-0;
  }
}

lime-form {
  label {
    @apply text-hlr-gunmetal font-mulish font-normal !important;
    font-size: medium;
  }
  #simpliform label {
    @apply text-hlr-gunmetal font-mulish !important;
    font-size: medium;
    font-weight: inherit;
  }
  #simpliform .book-btn {
    @apply flex items-center p-4 px-8 rounded-md px-3 align-middle cursor-pointer transition-opacity hover:opacity-60 font-semibold  bg-custom-green text-white !important;
  }
  #simpliform .btn-primary {
    @apply flex inline-flex gap-1 rounded-full bg-hlr-gunmetal px-7 py-1 text-sm font-bold text-white transition-opacity hover:opacity-60 border-hlr-form-border hover:bg-hlr-gunmetal !important;
  }
  #simpliform .next-btn {
    @apply flex hover:bg-custom-green border-hlr-form-border bg-custom-green items-center p-4 px-8 rounded-md align-middle cursor-pointer transition-opacity hover:opacity-60 font-semibold text-white !important;
    border: none;
  }
  #simpliform .form-control {
    @apply rounded border border-hlr-form-border bg-white p-2 text-lg !important;
    height: auto !important;
  }
  #simpliform .col-sm-4 {
    border: none;
  }
  #simpliform .prev-btn {
    @apply flex items-center text-sm p-4 px-8 rounded-md px-3 align-middle cursor-pointer transition-opacity hover:opacity-60 font-semibold px-8;
  }
  #simpliform strong {
    @apply text-hlr-gunmetal font-bold;
  }
  #simpliform .btn-success {
    @apply hover:bg-custom-green border-hlr-form-border bg-custom-green p-4 px-8 rounded-md cursor-pointer transition-opacity hover:opacity-60 font-semibold;
  }
  #simpliform .btn-sm {
    background-color: transparent !important;
  }
}
